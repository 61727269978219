
import {
  Component, Prop, Watch, Ref,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import WebMessage from '@/models/WebMessage'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { capitalize, clone } from 'lodash'
import IconAction from '@/components/IconAction/IconAction.vue'
import apexchart from 'vue-apexcharts'
import FormInput from '@/components/FormInput/FormInput.vue'
import Api from '@/models/Api'

@Component({
  components: {
    Widget,
    IconAction,
    apexchart,
    FormInput,
  },
})
export default class AdserverInspector extends ViewModel {
  @Ref('realtimeChart')
  public realtimeChart!: typeof apexchart

  public ws: WebSocket | null = null

  public events: any[] = []

  public expect_close = false

  private cap = 50

  public pending_events: any[] = []

  public chart_paused = false

  public request_params = {
    r: 'Click URL',
    rfc: 'Frequency Cap Timers',
    rcv: 'Creative VIew ID',
    pid: 'Publisher ID',
    pgid: 'Publisher Goal ID',
    pcid: 'Publisher Cap ID',
    dgid: 'Distribution Goal ID',
  }

  private timeout: any = null

  public interval: any = null

  public disconnect_in = 0

  public disconnect_interval: any = null

  public disconnect_dialog: any = null

  public ping_interval: any = null

  public filters = {
    ip: '',
    adunit: '',
    line_item: '',
  }

  public my_ip = ''

  public event_counts: any = {
    ad_request: 0,
    hit: 0,
    impression: 0,
    miss: 0,
    ad_events: 0,
  }

  public series = [
    {
      name: 'Ad Request',
      data: [],
    },
    {
      name: 'Hit',
      data: [],
    },
    {
      name: 'Impressions',
      data: [],
    },
    {
      name: 'Miss',
      data: [],
    },
    {
      name: 'Ad Events',
      data: [],
    },
  ]

  public chartOptions = {
    chart: {
      height: 350,
      type: 'line',
      stacked: true,
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 500,
        },
      },
      dropShadow: {
        enabled: true,
        opacity: 0.3,
        blur: 5,
        left: -7,
        top: 5,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      x: {
        format: 'HH:mm:ss',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    markers: {
      size: 0,
      hover: {
        size: 0,
      },
    },
    xaxis: {
      type: 'datetime',
      range: 27000,
    },
    title: {
      text: 'Events',
      align: 'left',
      style: {
        fontSize: '12px',
      },
    },
    legend: {
      show: true,
      floating: true,
      horizontalAlign: 'left',
      onItemClick: {
        toggleDataSeries: false,
      },
      position: 'bottom',
      offsetY: 40,
    },
  }

  public toggleChart() {
    this.chart_paused = !this.chart_paused
  }

  public initChart() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }

    this.interval = setInterval(() => {
      let series = []

      let idx = 0

      let date = Date.now()
      let offset = new Date().getTimezoneOffset() / 60
      date -= offset * 60 * 60 * 1000

      for (let key in this.event_counts) {
        let data = [
          ...this.realtimeChart.chart.w.config.series[idx].data,
          [date, this.event_counts[key]],
        ]
        this.event_counts[key] = 0
        if (data.length > 120) {
          // set data to the last 15 datapoints
          data = data.slice(data.length - 15)
        }

        series.push({
          data,
        })
        idx++
      }

      if (this.chart_paused) {
        return
      }
      this.realtimeChart.updateSeries(series)
    }, 1000)
  }

  public resetDisconnectInterval(showMessage = false) {
    this.disconnect_in = 10 * 60
    if (showMessage) {
      WebMessage.success('The connection time was extended in 10 minutes.')
    }
  }

  public connect() {
    this.disconnect(false)
    this.expect_close = false

    const system = getModule(SystemtModule)

    let uri = `${process.env.VUE_APP_BASE_ADSERVER_URL}ws?api_token=${system.api_token}`
    uri = uri.replace('https', 'wss').replace('http', 'ws')

    let filter_count = 0

    if (this.filters.ip == '' && (this.filters.adunit == '' || this.filters.line_item == '')) {
      WebMessage.error(
        'Please fill in the IP filter or the Adunit & Line Item filters before connceting to the ad server.',
      )
      return
    }

    if (this.filters.ip != '') {
      uri += `&ip=${encodeURIComponent(this.filters.ip)}`
      filter_count++
    }
    if (this.filters.adunit != '') {
      uri += `&adunit=${encodeURIComponent(this.filters.adunit)}`
      filter_count++
    }
    if (this.filters.line_item != '') {
      uri += `&line_item=${encodeURIComponent(this.filters.line_item)}`
      filter_count++
    }

    this.ws = new WebSocket(uri)

    this.ws.onopen = () => {
      WebMessage.success('Connected to AdServer, waiting for events...')
    }

    this.ws.onclose = () => {
      if (!this.expect_close) {
        WebMessage.error('The connection was interrupted with the AdServer, please reconnect.')
      }
      this.disconnect(false)
      this.ws = null
    }

    this.ws.onmessage = evt => {
      let e = JSON.parse(evt.data)
      e.data = JSON.parse(e.data)
      e.uuid = this.randomUUID()
      if (e.type == 'VastRequest') {
        e.showDetails = false
        e.showEvents = true
        e.events = []
        this.events.unshift(e)
        this.event_counts.ad_request++
      } else if (e.data.type !== 'request') {
        e.tries = 0
        this.pending_events.push(e)
      }

      this.processPendingEvents()
    }

    this.initChart()

    this.resetDisconnectInterval()
    if (this.disconnect_interval) {
      clearInterval(this.disconnect_interval)
    }
    this.disconnect_interval = setInterval(() => {
      this.disconnect_in--
      if (this.disconnect_in == 60) {
        // Show dialog
        this.$root.$emit('bv::show::modal', 'reconnect-modal')
      } else if (this.disconnect_in < 0) {
        this.disconnect(false)
        this.$root.$emit('bv::hide::modal', 'reconnect-modal')
        WebMessage.error(
          'You were away from the page for more than 10 minutes, the connection was interrupted with the AdServer, please reconnect.',
        )
      }
    }, 1000)

    this.ping_interval = setInterval(() => {
      if (this.ws) {
        this.ws.send('ping')
      }
    }, 1000 * 30)
  }

  public processPendingEvents() {
    if (this.pending_events.length === 0) {
      this.timeout = setTimeout(() => {
        this.processPendingEvents()
      }, 1000)
      return
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    let pending_events = [...this.pending_events]
    this.pending_events = []

    for (let i = 0; i < pending_events.length; i++) {
      let found = false
      for (let j = 0; j < this.events.length; j++) {
        if (this.events[j].data.id == pending_events[i].data.request_id) {
          found = true
          this.events[j].events.unshift(pending_events[i])
          if (typeof this.event_counts[pending_events[i].data.type] !== 'undefined') {
            this.event_counts[pending_events[i].data.type]++
          } else {
            this.event_counts.ad_events++
          }
          break
        }
      }

      if (!found) {
        pending_events[i].tries++
        if (pending_events[i].tries <= 10) {
          this.pending_events.push(pending_events[i])
        }
      }
    }

    if (this.events.length > this.cap) {
      while (this.events.length > this.cap) {
        this.events.pop()
      }
    }

    this.timeout = setTimeout(() => {
      this.processPendingEvents()
    }, 1000)
  }

  public disconnect(message = true) {
    if (this.ws) {
      this.expect_close = true
      this.ws.close()
      this.ws = null
      if (message) WebMessage.success('Disconnected from AdServer')
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }

    if (this.disconnect_interval) {
      clearInterval(this.disconnect_interval)
    }

    if (this.ping_interval) {
      clearInterval(this.ping_interval)
    }
  }

  public clear() {
    for (; this.events.length > 0;) {
      this.events.pop()
    }
  }

  public removeEvent(idx: number) {
    this.events.splice(idx, 1)
  }

  public getColor(type: string) {
    switch (type) {
      case 'hit':
        return 'success'
      case 'miss':
        return 'danger'
      default:
        return 'warning'
    }
  }

  public beforeDestroy() {
    this.disconnect(false)
    this.clear()
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
    if (this.disconnect_interval) {
      clearInterval(this.disconnect_interval)
    }
    if (this.ping_interval) {
      clearInterval(this.ping_interval)
    }
  }

  public fetchIP() {
    const api = new Api()

    api.get('user/my-ip').then(response => {
      this.my_ip = response.data.result.ip

      this.filters.ip = this.my_ip
    })
  }

  public mounted() {
    this.fetchIP()
  }
}
